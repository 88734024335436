import * as React from "react"
import ContactForm from "../components/global/contact-form/ContactForm"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"

const Contact = () => (
  <Layout>
    <Seo title="Contact NSC" />
    <div className="card card--center-text">
      <h1 className="text-red">
        PROFESSIONAL FREELANCE WEB DESIGNER MAIDSTONE, KENT
      </h1>
      <p className="center-text">
        I'm a professional web designer with years of business knowledge and
        expertise having run my own businesses and worked in all levels of
        business from Customer Service and Sales Manager, to Operations
        Director. This means I understand your goals as a business and can
        develop the right software solutions to help you grow.
      </p>
      <p className="center-text">
        My websites are built using the latest technologies so they are fast and
        of course they look great on every device.
      </p>
      <p className="center-text">
        If you hire me, you’ll be getting a friendly, easy to work with business
        professional who wants to help you succeed.
      </p>
    </div>
    <div className="card card--center-image">
      <StaticImage
        className="card-image__static-image card-image__static-image--profile"
        src="../images/dan280min.png"
        alt="Daniel Roberts"
        objectFit="contain"
      />
    </div>
    <ContactForm />
  </Layout>
)

export default Contact
